'use client';

import { Instagram, Linkedin } from 'lucide-react';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import CurrencySelection from './CurrencySelection';
import LanguageSelection from '../atoms/LanguageSelection';

export const FEATURE_FLAG_CURRENCY = false;

const AppFooter = ({ dark = false }) => {
  const t = useTranslations('Footer');
  return (
    <footer
      className={`border-t  dark:bg-gray-900 dark:border-gray-800 ${dark ? 'bg-primary border-gray-800' : ''}`}
    >
      <div className="container">
        <div className="flex flex-col items-center justify-between  py-5 text-sm text-gray-400 lg:flex-row lg:py-6">
          <div className="order-last flex flex-col items-center lg:order-none lg:flex-row z-10">
            <span className="mr-0 text-center lg:mr-4">© 2024 Railee, Europe.</span>
            <ul className="flex xs:list-disc space-x-6 text-nowrap">
              <li className="hover:underline">
                <Link href="/conditions">{t('conditions')}</Link>
              </li>
              <li className="hover:underline text-nowrap">
                <Link href="/legal">{t('legal')}</Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 flex flex-col items-center space-y-4 lg:mb-0 lg:flex-row lg:space-y-0 lg:space-x-12 z-10">
            <ul className="flex space-x-3">
              <li className="flex items-center">
                <Link
                  title="Instagram"
                  href="https://www.instagram.com/railee.eu/?u=s-footer"
                  className="rounded-full p-2 hover:bg-white"
                >
                  <Instagram size={16} />
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  title="Linkedin"
                  href="https://www.linkedin.com/company/railee/?u=s-footer"
                  className="rounded-full p-2 hover:bg-white"
                >
                  <Linkedin size={16} />
                </Link>
              </li>
            </ul>
            <ul className="flex items-center space-x-4">
              <li>
                <LanguageSelection dark={dark} />
              </li>
              {FEATURE_FLAG_CURRENCY && (
                <li>
                  <CurrencySelection dark={dark} />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
